import { QuestionAccordion } from '@/components/Faq/QuestionAccordion';

export function FaqSidebar() {
  return (
    <div className="flex h-full w-full flex-col py-4 font-sans lg:w-[420px] lg:border-l lg:border-white/10">
      <span className="mb-8 ml-4 font-mono uppercase text-stone-500 text-white/50">
        Frequently asked
      </span>

      <QuestionAccordion
        question="What is Redstone?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              Redstone is an{' '}
              <a
                href="https://ethereum.org/developers/docs/scaling/optimistic-rollups/"
                className="text-primary underline opacity-80"
              >
                Optimistic Rollup
              </a>{' '}
              building on the{' '}
              <a href="https://stack.optimism.io/" className="text-primary underline opacity-80">
                OP Stack
              </a>{' '}
              codebase. In practical terms, this means it is an Ethereum blockchain that inherits
              most security guarantees from L1 (Ethereum mainnet), but has much cheaper
              transactions. This is important because L1 transactions are typically too expensive
              for many use cases, such as games.
            </p>
            <p>
              The main difference between Redstone and{' '}
              <a href="https://stack.optimism.io" className="text-primary underline opacity-80">
                other OP Stack rollups
              </a>{' '}
              is data availability. Instead of using{' '}
              <a
                href="https://docs.optimism.io/landscape#ethereum-da"
                className="text-primary underline opacity-80"
              >
                L1 Ethereum for data availability (to post transactions)
              </a>
              , Redstone rollups use{' '}
              <a
                href="https://specs.optimism.io/experimental/plasma.html"
                className="text-primary underline opacity-80"
              >
              Plasma mode
              </a>
              , and just post input commitments to L1. This lets Redstone transactions be a lot cheaper.
            </p>
            <p>
              The tradeoff is that while data integrity is guaranteed by L1 Ethereum, data
              availability has to be guaranteed by other means. Redstone uses a{' '}
              <a href="/docs/what-is-redstone" className="text-primary underline opacity-80">
                challenge mechanism
              </a>{' '}
              to allow anyone to challenge the availability of the data corresponding to a given
              commitment on L1. You can inspect the commitment data and challenge commitments on the{' '}
              <a href="/data-availability" className="text-primary underline opacity-80">
                DA Explorer
              </a>{' '}
            </p>
          </div>
        }
      />

      <QuestionAccordion
        question="What is a bridge?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              Just as a physical bridge connects two physical locations, a blockchain bridge
              connects two blockchains. In this case, the bridge allows you to transfer assets
              between the L1 Ethereum mainnet and Redstone, or between Holesky and Garnet 
              (our testnet).
            </p>
            <p>
              For more information, see{' '}
              <a href="https://ethereum.org/en/developers/docs/bridges/" className="text-primary underline opacity-80">
                ethereum.org
              </a>
              .{' '}
            </p>
          </div>
        }
      />
      <QuestionAccordion
        question="What are the Redstone bridges?"
        answer="The Redstone bridge enables the transfer of ETH and other tokens between the L1 (Ethereum) and L2 (Redstone)."
      />
      <QuestionAccordion
        question="Are there other ways to bridge funds?"
        answer={
          <>
            <a
              href="https://relay.link/bridge/redstone/"
              target="_blank"
              rel="noreferrer"
              className="text-white/80 hover:underline"
            >
              Relay
            </a>{' '}
            is a third-party that offers fast L1&lt;&gt;L2 and L2&lt;&gt;L2 bridging for a small
            fee. Other third-party bridges will be coming soon.
          </>
        }
      />
      <QuestionAccordion
        question="Which wallets can I use?"
        answer={
          <div className="space-y-3">
            <p>Currently supported:</p>
            <ul className="ml-6 list-disc">
              <li>MetaMask</li>
              <li>Coinbase Wallet, but you have to add and switch networks manually</li>
              <li>WalletConnect, if the wallet supports custom networks</li>
            </ul>
          </div>
        }
      />
      <QuestionAccordion
        question="What if I have a question or problem?"
        answer={
          <div className="flex flex-col space-y-4">
            <p>
              You are invited to the{' '}
              <a href="https://lattice.xyz/discord" className="text-primary underline opacity-80">
                Lattice Discord
              </a>
              , where the #redstone-help channel is available to answer your questions.{' '}
            </p>
          </div>
        }
      />
    </div>
  );
}
