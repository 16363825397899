import { ReactNode, useCallback, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';

type QuestionAccordionProps = {
  question: string;
  answer: ReactNode;
};

export function QuestionAccordion({ question, answer }: QuestionAccordionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => setIsOpen((o) => !o), [setIsOpen]);

  return (
    <div className="flex flex-col gap-6 border-b border-white/10 p-4">
      <button
        type="button"
        className="flex flex-row justify-between text-base"
        onClick={toggleOpen}
      >
        <span className="text-left">{question}</span>
        <span>
          {isOpen ? (
            <ChevronUpIcon className="ml-2 h-4 text-white" />
          ) : (
            <ChevronDownIcon className="ml-2 h-4 text-white" />
          )}
        </span>
      </button>
      {isOpen && <span className="text-base text-white/50">{answer}</span>}
    </div>
  );
}
